<template>
    <b-container fluid>
      <b-row>
        <b-col>
          <b-card class="mb-0">
            <h5>
              <router-link :to="{ name: 'Sanction', params: { id: item.id }}"><h5>{{item.name}}</h5></router-link>
            </h5>
              <div class="mb-0"><small>
                record created: {{new Intl.DateTimeFormat().format(new Date(item.created_at))}} |
                case started: {{new Intl.DateTimeFormat().format(new Date(item.start))}} |
                case ended: {{new Intl.DateTimeFormat().format(new Date(item.end))}}
              </small></div>
              <div class="mb-0" v-html="item.body"> </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
export default {
  name: 'CaseSimple',
  props: ['item', 'username'],
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  }
}
</script>
