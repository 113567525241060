<template>
  <b-col>
    <b-form inline>
      <!-- <b-button variant="outline-primary" @click="download" class="mr-2 button">download</b-button> -->
      <!-- <b-form-select class ="button" v-model="viewSelected" :options="viewOptions" id="view-select"></b-form-select> -->
      sort by:
      <b-form-select class="mx-2" v-model="sortProperty" :options="sortPropertyOptions"></b-form-select>
      <b-form-select v-model="sortDirection" :options="sortDirectionOptions"></b-form-select>
    </b-form>
    <b-card class="my-3 mb-2">
      <b-pagination v-if="rowsShow.length > perPage"
        :total-rows="totalRows"
        v-model="currentPage"
        :per-page="perPage"
      />
      <div class="mb-3" v-for="row in rowsSplit" :key="row.id">
          <line-item
          :item="row"
          />
      </div>
      <b-pagination class="mb-0" v-if="rowsShow.length > perPage"
        :total-rows="totalRows"
        v-model="currentPage"
        :per-page="perPage"
      />
    </b-card>
  </b-col>
</template>

<script>
import LineItem from '@/components/SanctionSimple.vue'

export default {
  name: 'Sanctions',
  components: {
    LineItem
  },
  computed: {
    rowsShow () {
      return this.$store.state.rowsShow
    },
    rowsSplit () {
      const items = this.rowsShow
      // Return just page of items needed
      return items.slice(
        (this.currentPage - 1) * this.perPage,
        this.currentPage * this.perPage
      )
    },
    sortDirection: {
      get () {
        return this.$store.state.sortDirection
      },
      set (payload) {
        this.$store.commit('setSortDirection', payload)
      }
    },
    sortProperty: {
      get () {
        return this.$store.state.sortProperty
      },
      set (payload) {
        this.$store.commit('setSortProperty', payload)
      }
    },
    sortPropertyOptions () {
      return this.$store.state.sortPropertyOptions
    },
    totalRows () {
      return this.rowsShow.length
    }
  },
  created: function () {
    document.title = "Sanctions"
  },
  data () {
    const data = {
      currentPage: 1,
      perPage: 20,
      sortDirectionOptions: [
        { value: 'asc', text: 'ascending' },
        { value: 'desc', text: 'descending' }
      ]
    }
    return data
  },
  methods: {
  }
}
</script>

<style>
</style>
